import React, { useState } from 'react'
import styled from '@emotion/styled'
import Section from 'src/components/Section'
import Grid from 'src/components/Grid'
import TextLockup from 'src/components/TextLockup'
import Button from 'src/components/Button'
import TextLink from 'src/components/TextLink'
import { GatsbyImage } from 'gatsby-plugin-image'
import { util, colors, animations } from 'src/styles'
import { MdArrowForward, MdPhone } from 'react-icons/md'

const Wrapper = styled(Section)`
`
const Content = styled.div`
	text-align: center;
	margin: 0 auto;
	p {
		max-width: 38em;
		margin-left: auto;
		margin-right: auto;
	}
	> h3 {
		margin-top: 0;
	}
`

const Action = styled.div`

`

const RestaurantActions = styled.div`
	margin-top: 24px;
	display: flex;
	margin-left: -10px;
	margin-right: -10px;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	${ Action } {
		max-width: 200px;
		flex-grow: 1;
		flex-shrink: 0;
		margin-bottom: 16px;
		margin-left: 10px;
		margin-right: 10px;
		.button {
			width: 100%;
		}
	}
`

const RestaurantLogo = styled(GatsbyImage)`
	${ util.responsiveStyles('max-width', 250, 200, 190, 170) }
	margin: 0 auto;
	${ util.responsiveStyles('margin-bottom', 40, 30, 20, 18) }
`

const RestaurantWebLink = styled(TextLink)`
	margin-top: 16px;
	svg {
		display: inline-block;
		vertical-align: middle;
		margin-bottom: 3px;
	}
`

const PopOutWrap = styled.div`
	position: relative;
	z-index: 2;
	> .button {
		.icon {
			// transition: color ${ animations.mediumSpeed } ease-in-out;
		}
		&.expanded {
			background: ${ colors.mainColor };
			color: ${ colors.lightGrey };
			border-color: ${ colors.mainColor };
		}
	}
	.items {
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		z-index: 2;
		border: 2px solid ${ colors.textColor };
		border-top: none;
		.button {
			padding-left: 1em;
			padding-right: 1em;
			background: ${ colors.bgColor };
			border: none;
			.button-text {
				white-space: nowrap;
				overflow: hidden;
				max-width: 100%;
				text-overflow: ellipsis;
			}
			&:hover {
				background: ${ colors.lightGrey };
				color: ${ colors.textColor };
				.icon {
					color: ${ colors.mainColor };
				}
			}
		}
		.button-content {
			text-align: left;
			justify-content: space-between;
		}
		.icon {
			font-size: 18px;
			transform: rotate(-45deg);
			transition: color ${ animations.mediumSpeed } ease-in-out;
		}
	}
`

const RestaurantSection = ({ className, restaurant }) => {
	const hasOrderLinks = restaurant.orderOnlineLinks && restaurant.orderOnlineLinks.length > 0
	const hasMenus = restaurant.menuFiles && restaurant.menuFiles.length > 0
	const [ menuListOpen, setMenuListOpen ] = useState(false)
	const [ orderListOpen, setOrderListOpen ] = useState(false)

	const expandItems = type => {
		if (type === 'menuList') {
			setMenuListOpen(!menuListOpen)
			setOrderListOpen(false)
		}
		if (type === 'orderList') {
			setOrderListOpen(!orderListOpen)
			setMenuListOpen(false)
		}
	}

	return (
	  <Wrapper className={className}>
			<Grid small='1 [12] 1'>
				<div>
					<Content>
						{(restaurant.logo && restaurant.logo.gatsbyImageData) ? (
							<RestaurantLogo image={restaurant.logo.gatsbyImageData} sizes={'500px'} title={restaurant.title} />
						) : (
							<h3>{restaurant.title}</h3>
						)}
						<TextLockup
							text={restaurant.description}
							textSize='bodyMedium'
						/>
						{(hasMenus || hasOrderLinks) && (
							<RestaurantActions>
								{restaurant.orderOnlineLinks && restaurant.orderOnlineLinks.length > 0 && (
									<Action>
										{restaurant.orderOnlineLinks.length > 1 ? (
											<PopOutWrap>
												<Button
													iconPosition='right'
													icon={orderListOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
													onClick={() => expandItems('orderList')}
													setTheme='secondary' external name='Order Online'
													title='Order Online'
													className={orderListOpen ? 'expanded' : ''}
												>
													Order Online
												</Button>
												{orderListOpen && (
													<div className='items'>
														{restaurant.orderOnlineLinks.map((link, index) => {
															return (
																<Button icon='arrow_forward' iconPosition='right' to={link.value} target='_blank' setTheme='secondary' external title={'Order Online at ' + link.key}>{link.key}</Button>
															)
														})}
													</div>
												)}
											</PopOutWrap>
										) : (
											<Button to={restaurant.orderOnlineLinks[0].value} setTheme='secondary' target='_blank' external name='View Menu' title='View Menu'>Order Online</Button>
										)}
									</Action>
								)}
								{(restaurant.menuFiles && restaurant.menuFiles[0].file) && (
									<Action>
										{restaurant.menuFiles.length > 1 ? (
											<PopOutWrap>
												<Button
													iconPosition='right'
													icon={menuListOpen ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
													onClick={() => expandItems('menuList')}
													setTheme='secondary' external name='View Menu'
													title='View Menus'
													className={menuListOpen ? 'expanded' : ''}
												>
													View Menus
												</Button>
												{menuListOpen && (
													<div className='items'>
														{restaurant.menuFiles.map((menu, index) => {
															return (
																<Button icon='arrow_forward' iconPosition='right' to={menu.file.url} target='_blank' setTheme='secondary' external name='View Menu' title='View Menu'>{menu.title}</Button>
															)
														})}
													</div>
												)}
											</PopOutWrap>
										) : (
											<Button to={restaurant.menuFiles[0].file.url} setTheme='secondary' target='_blank' external name='View Menu' title='View Menu'>View Menu</Button>
										)}
									</Action>
								)}
							</RestaurantActions>
						)}

						{restaurant.websiteUrl && (
							<RestaurantWebLink
								to={restaurant.websiteUrl}
								target='_blank'
								external
							>
								{restaurant.websiteUrl} <MdArrowForward size='18px' />
							</RestaurantWebLink>
						)}

						{restaurant.phoneNumber && (
							<div>
								<RestaurantWebLink
									to={'tel:' + restaurant.phoneNumber}
									target='_blank'
									external
								>
									<MdPhone size='18px' /> {restaurant.phoneNumber}
								</RestaurantWebLink>
							</div>
						)}
					</Content>
				</div>
			</Grid>
	  </Wrapper>
  )
}

export default RestaurantSection
