import React from 'react'
import styled from '@emotion/styled'
import Grid from 'src/components/Grid'
import ResponsiveComponent from 'src/components/ResponsiveComponent'
import { GatsbyImage } from 'gatsby-plugin-image'
import Slider from 'react-slick'
import { mq, colors, animations, util } from 'src/styles'

const Wrapper = styled.div`
	background: ${ colors.textColor };
	> div {
		z-index: 1;
	}
`

const StyledGrid = styled(Grid)`
	height: 100%;
`

const ImageWrapper = styled.div`
	background: ${ colors.charcoal };
	height: 100vw;
	min-height: 60vw;
	overflow: hidden;
	${ mq.mediumAndUp } {
		height: 66vh;
	}
	${ mq.largeAndUp } {
		height: 66vh;
		min-height: 33vw;
	}
	.gatsby-image-wrapper {
		height: 100%;
	}
`

const SlideshowDots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
  padding-top: 60px;
  ${ util.responsiveStyles('padding-bottom', 40, 30, 24, 22) }
  margin: 0;
  background: linear-gradient(0deg, rgba(0, 0, 0, .5) 0%, rgba(0, 0, 0, 0) 100%);
  li {
  	&:not(:first-of-type) {
	  	a {
		  	margin-left: 20px;
	  	}
	  }
  }
`

const SlideshowDot = styled.a`
	display: block;
  width: 10px;
  height: 10px;
  background: ${ colors.white };
  cursor: pointer;
  border-radius: 50%;
  transform: scale(0.6);
  opacity: .7;
  transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out, background ${ animations.mediumSpeed } ease-in-out;
  .slick-active & {
    transform: none;
    opacity: 1;
    background: ${ colors.white };
  }
`

const gridSetup = [
	{
		small: '[1]',
		medium: '[1]',
		large: '[1]'
	},
	{
		small: '[1]',
		medium: '[1] [1]',
		large: '[1] [1]'
	},
	{
		small: '[1]',
		medium: '[1] [1]',
		large: '[1] [1] [1]'
	}
]

const RestaurantAtf = ({ className, images }) => {
	if (!images || images.length < 1) {
		return false
	}

	let sliderSettings = {
	  dots: true,
	  arrows: false,
	  infinite: true,
	  speed: 500,
	  slidesToShow: images.length <= 3 ? images.length : 3,
	  slidesToScroll: 1,
	  variableWidth: false,
	  autoplay: true,
	  autoplaySpeed: 3000,
	  adaptiveHeight: false,
	  accessibility: false,
	  appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
	  customPaging: (slider, i) => (
	    <SlideshowDot active={slider === i}>
	      {i}
	      {slider.current}
	    </SlideshowDot>
	  ),
	  responsive: [
	  	{
	  		breakpoint: 1000,
	  		settings: {
	  			slidesToShow: images.length <= 2 ? images.length : 2
	  		}
	  	},
	  	{
	  		breakpoint: 800,
	  		settings: {
	  			slidesToShow: 1
	  		}
	  	}
	  ]
	}

	return (
        <Wrapper className={className}>
			<Slider {...sliderSettings}>
				{images.map((image, index) => {
					return (
                        <ImageWrapper key={'restaurant-atf-image-' + index}>
							<GatsbyImage image={image.gatsbyImageData} loading='eager' />
						</ImageWrapper>
                    );
				})}
			</Slider>
	  </Wrapper>
    );
}

export default RestaurantAtf
