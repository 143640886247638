import React, { useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import { css } from '@emotion/core'
import Section from 'src/components/Section'
import RestaurantThumbnail from 'src/components/RestaurantThumbnail'
import Grid from 'src/components/Grid'
import Button from 'src/components/Button'
import Slider from 'react-slick'
import { util, typography, mq, animations, colors } from 'src/styles'

const Wrapper = styled(Section)``

const StyledSlider = styled(Slider)`
	${util.responsiveStyles('padding-bottom', 80, 60, 40, 30)}
`

const SlideWrapper = styled.div`
	width: 50vw;
	max-width: 50vw;
	min-width: 50vw;
	padding-right: 14px;
	flex: 1;
	${ mq.mediumAndUp } {
		width: 35vw;
		max-width: 35vw;
		min-width: 35vw;
		padding-right: 20px;
	}
	${ mq.largeAndUp } {
		width: 28vw;
		max-width: 28vw;
		min-width: 28vw;
		padding-right: 24px;
	}
	${ mq.largerAndUp } {
		width: 26vw;
		max-width: 26vw;
		min-width: 26vw;
	}
	${ mq.extraLargeAndUp } {
		width: 20vw;
		max-width: 20vw;
		min-width: 20vw;
		padding-right: 30px;
	}
`

const SlideshowDots = styled.ul`
  list-style: none;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 9999;
  li {
  	&:not(:first-of-type) {
	  	a {
		  	margin-left: 10px;
	  	}
	  }
  }
`

const SlideshowDot = styled.a`
	display: block;
  width: 10px;
  height: 10px;
  background: ${ colors.textColor };
  cursor: pointer;
  border-radius: 50%;
  transform: scale(0.6);
  opacity: .2;
  transition: transform ${ animations.mediumSpeed } ease-in-out, opacity ${ animations.mediumSpeed } ease-in-out, background ${ animations.mediumSpeed } ease-in-out;
  .slick-active & {
    transform: none;
    opacity: 1;
    background: ${ colors.mainColor };
  }
`

let settings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,
  variableWidth: true,
  autoplay: false,
  autoplaySpeed: 3000,
  adaptiveHeight: false,
  accessibility: false,
  appendDots: dots => <SlideshowDots>{dots}</SlideshowDots>,
  customPaging: (slider, i) => (
    <SlideshowDot active={slider === i}>
      {i}
      {slider.current}
    </SlideshowDot>
  ),
}

const OtherRestaurants = ({ className, restaurants }) => {

	const slider = useRef(null)
	if (restaurants.length < 1) {
		return false
	}

	let goToPrev = () => {}
	let goToNext = () => {}

	useEffect(() => {
		goToPrev = slider.current.slickPrev
		goToNext = slider.current.slickNext
	}, [])

	return (
		<Wrapper className={className} setTheme='lightGrey'>
	  	<Grid small='1 [9] [3] 1' large='1 [20] [4] 1' vAlign='center' css={css`${ util.responsiveStyles('margin-bottom', 91, 51, 33, 26) }`}>
		  	<div><h4 css={css`margin: 0; ${ typography.h2 } ${ mq.smallAndBelow } { ${ typography.h3 } }`}>More Restaurants</h4></div>
		  	<div style={{ textAlign: 'center' }}>
		  		<Button onClick={() => goToPrev()} icon='keyboard_arrow_left' shape='circle' setTheme='transparent' css={css`.icon { font-size: 30px; } &:hover { background: white; }`} />
		  		<Button onClick={() => goToNext()} icon='keyboard_arrow_right' shape='circle' setTheme='transparent' css={css`margin-right: -16px; .icon { font-size: 30px; } &:hover { background: white; }`} />
		  	</div>
	  	</Grid>

	  	{restaurants.length <= 4 ? (
		  	<Grid small='1 [6] [6] 1' large={restaurants.length === 3 ? '1 [8] [8] [8] 1' : '1 [6] [6] [6] [6] 1'} vAlign='top' colGap='16px;'>
			  	{restaurants.map((restaurant, index) => {
			  		restaurant = restaurant.node
			  		return (
				  		<div>
								<RestaurantThumbnail
									key={'other-restaurants-' + index}
									restaurant={restaurant}
								/>
							</div>
						)
					})}
		  	</Grid>
	  	) : (
	  		<Grid small='1 [13]' large='1 [26]'>
			  	<StyledSlider {...settings} ref={slider}>
				  	{restaurants.map((restaurant, index) => {
				  		restaurant = restaurant.node
				  		return (
					  		<SlideWrapper>
									<RestaurantThumbnail
										key={'other-restaurants-' + index}
										restaurant={restaurant}
										showSummary={false}
									/>
								</SlideWrapper>
							)
					  })}
					</StyledSlider>
				</Grid>
			)}
	  </Wrapper>
	)
}

export default OtherRestaurants
