import React, { Fragment } from 'react'
import { graphql } from 'gatsby'
import * as PropTypes from 'prop-types'

import SEO from 'src/components/SEO'
import ComponentRenderer from 'src/components/ComponentRenderer'
import Header from 'src/components/Header'
import Footer from 'src/components/Footer'
import RestaurantAtf from 'src/components/RestaurantAtf'
import RestaurantSection from 'src/components/RestaurantSection'
import OtherRestaurants from 'src/components/OtherRestaurants'

const propTypes = {
	data: PropTypes.object.isRequired,
}

class PageTemplate extends React.Component {
	render () {
		const site = this.props.data.allContentfulSiteSettings && this.props.data.allContentfulSiteSettings.edges[0].node
		const page = this.props.data.contentfulRestaurant
		const restaurants = this.props.data.allContentfulRestaurant.edges
		const restaurantsMinusCurrent = restaurants.filter(restaurant => restaurant.node.id !== page.id)
		const { sections } = page
		const hasAtf = sections && sections[0].__typename === 'ContentfulWideMedia' && sections[0].width === 'fullWidth'
		const seo = page.seo

		return (
			<Fragment>
				<SEO
					title={page.title}
					description={page.shortDescription}
					siteSettings={site}
					// keywords={seo && seo.keywords}
					shareImage={'https:' + page.thumbnail.file.url}
				/>
				<Header
					hasAtf={true}
					bannerText={site.bannerText}
          bannerColor={site.bannerColor}
          navigation={site.navigation}
          socialLinks={site.socialLinks}
          location={page.slug}
          title={site.title}
          restaurants={restaurants}
					site={site}
				/>
				<RestaurantAtf images={page.topImages}/>

				<RestaurantSection restaurant={page}/>

				{sections && sections.map((section, index) => {
					const prevTheme = ((index !== 0) && sections[index - 1]) && sections[index - 1].theme
					const prevFullWidth = ((index !== 0) && sections[index - 1]) && sections[index - 1].width === 'fullWidth'
					const nextTheme = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].theme
					const nextFullWidth = ((index !== sections.length - 1) && sections[index + 1]) && sections[index + 1].width === 'fullWidth'
					const lastSection = sections.length === index + 1
					return (
						<ComponentRenderer
							prevTheme={prevFullWidth ? false : prevTheme}
							nextTheme={nextFullWidth ? false : nextTheme}
							isFirstSection={index === 0}
							isLastSection={lastSection}
							key={section.id + index}
							item={section}
							index={index}
						/>
					)
				})}
				<OtherRestaurants restaurants={restaurantsMinusCurrent} />
				<Footer {...site} />
			</Fragment>
		)
	}
}

PageTemplate.propTypes = propTypes

export const pageQuery = graphql`
  query($id: String!) {
		allContentfulSiteSettings(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}) {
	    edges {
	      node {
	        ...SiteSettings
	      }
	    }
	  }
    contentfulRestaurant(id: {eq: $id}) {
			...Restaurant
    }
    allContentfulRestaurant(filter: {internalName: {regex: "/^((?!PLACEHOLDER).)*$/"}}, sort: {order: ASC, fields: title}, ) {
	    edges {
	      node {
	        ...Restaurant
	      }
	    }
	  }
	}
`

export default PageTemplate
